import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../../common/Hooks';
import TableView from '../../../common/LayoutComponents/Table';

const useReportSyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    flex: 1
  }
}));

function Discrepancy({ value }) {
  const color = parseInt(value) > 0 ? 'green' : 'red';
  return <span style={{ color }}>{value}</span>;
}

function ReportLine({ id, reportLine }) {
  return (
    <tr>
      {Object.entries(reportLine).map(([_, value], i) =>
        i !== 3 ? (
          <td>{value}</td>
        ) : (
          <td>
            <Discrepancy value={value} />
          </td>
        )
      )}
    </tr>
  );
}

function ReportTable({ headers, data }) {
  return (
    <TableView header={['Model', ...headers]} initialRowsPerPage={Object.keys(data).length}>
      {Object.entries(data).map(([key, reportLine]) => (
        <ReportLine key={key} id={key} reportLine={reportLine} />
      ))}
    </TableView>
  );
}

export default function DiscrepancyReport() {
  const [request, response] = useHttp('ims/fba/discrepancy-report');
  const [fbaReport, setFbaReport] = useState({});
  const classes = useReportSyles();
  async function getDiscrepancyReports() {
    const fbaRequest = await request.get('/fba');
    if (response.ok) {
      setFbaReport(fbaRequest);
    }
  }

  useEffect(() => {
    getDiscrepancyReports();
  }, []);

  if (request.loading) return 'Loading';

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={classes.paper}>
          <h2>FBA</h2>
          <ReportTable headers={['Amazon FBA', 'Walts FBA', 'Discrepancy']} data={fbaReport} />
        </div>
      </div>
    </Paper>
  );
}
