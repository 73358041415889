import { PurchaseOrder } from '../../common/Types/ApiOsposDbModels/PurchaseOrderTypes';
import { UpdatePurchaseOrderRequest } from './PurchaseOrderDetailsTypes';

export function makeUpdatePurchaseOrderRequestFromPurchaseOrder(
  purchaseOrder: PurchaseOrder
): UpdatePurchaseOrderRequest {
  if (!purchaseOrder.purchase_order_status) {
    throw new Error('Missing purchase_order_status in PurchaseOrder object.');
  }
  return {
    purchase_order_type: purchaseOrder.purchase_order_type,
    vendor_id: purchaseOrder.vendor_id,
    tracking_number: purchaseOrder.tracking_number,
    carrier_name: purchaseOrder.carrier_name,
    notes: purchaseOrder.notes,
    ims_location_code: purchaseOrder.ims_location_code,
    paid: purchaseOrder.paid,
    vendor_order_id: purchaseOrder.vendor_order_id,
    purchase_order_status: purchaseOrder.purchase_order_status,
    dfi_discount_percentage: purchaseOrder.dfi_discount_percentage,
    purchase_terms_id: purchaseOrder.purchase_terms_id
  };
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});
